import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: red,
        background: '#333333'
    },
    typography: {
        headingFont: 'Roboto',
        bodyFont: 'Open Sans',
        fontFamily: [
            'Open Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        htmlFontSize: 10,
        fontSize: 16,
        h1: {
            fontSize: '62px',
            fontWeight: "700",
            lineHeight: 1.5,
            color: '#333333'
        },
        h2: {
            fontSize: '32px',
            fontWeight: '600',
            lineHeight: 1.5,
            color: '#333333'
        },
        h3: {
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: 1.5,
            color: '#333333'
        },
        h4: {
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: 1.5,
            color: '#333333'
        },
        h5: {
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: 1.5,
            color: '#021324'
        },
        h6: {
        },
        subtitle1: {
            fontSize: '16px',
            fontWeight: '700',
            color: '#333333',
            textTransform: 'uppercase'
        },
        subtitle2: {
            fontFamily: 'Open Sans'
        },
        body1: {
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: 1.75,
            color: '#686868',
            '@media (max-width:1024px)': {
                fontSize: '12px',
            },
        },
        body2: {
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: 1.5,
            color: '#333333'
        },
        button: {
            fontFamily: 'Open Sans'
        },
        caption: {
            fontFamily: 'Roboto',
            fontSize: '16px',
            lineHeight: 1.5,
        },
        overline: {
            fontFamily: 'Open Sans',
            fontSize: '12px',
            lineHeight: 1.2,
            color: '#333333'
        }
    }
});

export default theme;