import { combineReducers } from "redux";

import { auth } from "./auth";
import { global } from "./global";
import { massaction } from './mass_action';
import { sandboxes } from './sandboxes';
import { products } from './products';
import { users } from "./users";
import { maintenance } from './maintenance';
import { misc } from "./misc";

export default combineReducers({ 
    auth, 
    global,
    massaction,
    sandboxes,
    products,
    users,
    maintenance,
    misc
});