import React from 'react';
import styled from "styled-components";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import theme from '../../theme';

import { isArrayExists } from '../../helpers/validation';

class FormRadio extends React.Component {

    handleChange = (event) => {
        const { name } = this.props;
        if ( this.props.onChange ) {
            this.props.onChange(event.target.value,name);
        }
    }

    render() {
        const { label, value, disabled, options, radioProps } = this.props;
        return (
        <FormControl fullWidth={true} style={{ padding: '0 5px' }}>
            <FormLabel component="legend">{( label || '' )}</FormLabel>
            <RadioGroup 
                value={value}
                disabled={( disabled || false )}
                onChange={this.handleChange}>
                { options && isArrayExists( options ) ? options.map(option => {
                    return <FormControlLabel key={option.value} value={option.value} control={<Radio
                        color="primary"
                        {...radioProps}
                    />} label={option.label} />
                }) : null }
            </RadioGroup>
        </FormControl>
        )
    }

}

export default FormRadio