import * as firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getProducts = () => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'product', action: 'get_products' })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getProduct = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'product', action: 'get_product', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_addNewProduct = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'product', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateProduct = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'product', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteProduct = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'product', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}