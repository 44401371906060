import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import CardSandbox from '../../components/CardSandbox';

class DashboardPage extends React.Component {

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    renderContents() {
        return (
        <div>

            <CardSandbox />

        </div>
        );
    }

    render() {
        return <AppWrapper 
                title=""
                maxWidth="1110px"
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        sample: state.sample || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(DashboardPage);