import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _split from 'lodash/split';

// import { isArrayExists } from './validation';

// check if current user is admin
export const isAdmin = function(authData) {
    return ( authData && authData.type && ( authData.type === 'admin' || authData.type === 'superadmin' ) ? true : false );
}

// check if current user is super admin
export const isSuperAdmin = function(authData) {
    return ( authData && authData.type && authData.type === 'superadmin' ? true : false );
}

// check if current user have the access rights
export const hasAccessRights = function(authData,accessRights) {
    return ( authData && !_isEmpty( authData ) && (
        ( isAdmin(authData) || !accessRights ) || 
        ( accessRights && authData.accesses && !_isEmpty( authData.accesses ) && _find( accessRights, (r) => authData.accesses.indexOf(r) >= 0 ) )
    ) ? true : false );
}