import * as firebase from 'firebase/app';

import { doPromise, callFunctionsAPI } from '../../helpers/action';

import { FIREBASE_HOSTING_URL } from '../../constants';

// get user data via onAuth
export const onAuth = (userToken) => {
    return new Promise((resolve, reject) => {
        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'auth',
                params: [{ key: 'action', value: 'auth' }]
            })
            .then(res => {
                var user = res.data;
                user['photoURL'] = ( userToken.photoURL || '' );
                resolve(user);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

// register user upon sign-up
export const onAccountCreated = () => {
    return new Promise((resolve, reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'auth',
                params: [{ key: 'action', value: 'create_account' }]
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

// get current user
export const getCurrentUser = () => {
    return firebase.auth().currentUser;
}

// get current user ID token
export const getIDToken = function(authHeader) {
    return new Promise((resolve,reject) => {

        firebase.auth().currentUser.getIdToken(true)
        .then(function(idToken) {
            resolve(( authHeader ? 'Bearer ' + idToken : idToken ));
        })
        .catch(function(error) {
            reject(error);
        });
          
    });
}

// perform login
export const logoutUser = () => {
    return new Promise((resolve,reject) => {

        firebase.auth().signOut().then(function() {
            resolve('logged_out');
        }).catch(function(error) {
            reject(error);
        });
          
    });
}

// perform google sign-in
export const googleSignIn = () => {
    return new Promise((resolve,reject) => {

        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');

        firebase.auth().signInWithPopup(provider).then(function(result) { 
            onAccountCreated()
            .then( user => {
                user['photoURL'] = ( result && result.user && result.user.photoURL || '' );
                resolve(user);
            })
            .catch(function(error) {
                reject(error);
            });
        }).catch(function(error) {
            reject(error);
        });
          
    });
}

// send email sign-in link
export const sendEmailSignInLink = (email) => {
    return new Promise((resolve,reject) => {

        firebase.auth().sendSignInLinkToEmail(email, {
            url: FIREBASE_HOSTING_URL +'emailSignIn',
            handleCodeInApp: true,
        })
        .then(function() {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem('emailForSignIn', email);
            resolve('done');
        })
        .catch(function(error) {
            reject(error);
        });
          
    });
}

// verify email sign-in link
export const verifyEmailSignInLink = () => {
    return new Promise((resolve,reject) => {

        // Confirm the link is a sign-in with email link.
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.

            var email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }

            // The client SDK will parse the code from the link for you.
            firebase.auth().signInWithEmailLink(email, window.location.href)
            .then(function(result) {
                // Clear email from storage.
                window.localStorage.removeItem('emailForSignIn');

                // trigger on account created
                onAccountCreated()
                .then( user => {
                    user['photoURL'] = '';
                    resolve(user);
                })
                .catch(function(error) {
                    reject(error);
                });
            })
            .catch(function(error) {
                reject(error);
            });
        }
          
    });
}

// get user profile
export const fb_getProfile = () => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'auth', action: 'get_profile' })
        .then(user => {
            resolve(user);
        })
        .catch(error => {
            reject(error);
        });
          
    });
}

// update user profile
export const fb_updateProfile = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'auth', action: 'update_profile', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
          
    });
}