import _random from 'lodash/random';

import {
	GET_USERS,
	GET_USERS_OPTIONS,
    ADD_USER,
	EDIT_USER,
	EDIT_USERS,
    DELETE_USER,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getUsers, fb_getUsersOptions, fb_addUser, fb_editUser, fb_editUsers, fb_deleteUser } from './fb';

export const getUsers = () => {
	return dispatch => {

		fb_getUsers()
		.then(list => {
			dispatch({
				type: GET_USERS,
				payload: { list }
			});	
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve users list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_USERS,
				payload: { list: [] }
			});
		})

	}
}

export const getUsersOptions = () => {
	return dispatch => {

		fb_getUsersOptions(list => {
			dispatch({
				type: GET_USERS_OPTIONS,
				payload: { list }
			});	
		});

	}
}

export const addUser = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addUser(formData)
		.then( results => {
			fb_getUsers()
			.then(list => {
				dispatch({ type: GET_USERS, payload: { list } });	
				dispatch({ type: ADD_USER });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("User Added");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new user' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editUser = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_editUser(formData)
		.then( results => {
			fb_getUsers()
			.then(list => {
				dispatch({ type: GET_USERS, payload: { list } });	
				dispatch({ type: EDIT_USER });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("User Updated");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to edit user' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editUsers = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_editUsers(formData)
		.then( results => {
			fb_getUsers()
			.then(list => {
				dispatch({ type: GET_USERS, payload: { list } });	
				dispatch({ type: EDIT_USERS });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("User(s) Updated");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to edit users' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteUser = (uid) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalDeleting(true));

		fb_deleteUser(uid)
		.then( results => {
			fb_getUsers()
			.then(list => {
				dispatch({ type: GET_USERS, payload: { list } });	
				dispatch({ type: DELETE_USER });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("User Deleted!");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete user' ) );
			triggerErrorAlert(errMsg);
		});

	}
}