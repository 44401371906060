import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _upperFirst from 'lodash/upperFirst';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import ButtonActions from '../../components/ButtonActions';
import FormSelect from '../../components/FormSelect';
import FormInput from '../../components/FormInput';

import { InfoButton, ErrorButton, ButtonGroup, SuccessButton, GreyButton, InverseButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';
import { SuccessTag, AmberTag, GreyTag } from '../../styles/tag';

import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';

import { addUser, editUser, editUsers, deleteUser } from '../../actions/users';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top',
        fontWeight: '400'
    }
});


class Users extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleFormUpdate = (newValue,key) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.type && !_isEmpty( modalData.type ) ) ) {
            error = 'Please select a user type';
        } // end - modalData.type

        if ( !( modalData && modalData.email && validateEmail( modalData.email ) ) ) {
            error = 'Please insert a valid email address';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addUser(modalData));
        } // end - error
    }

    handleEdit = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.type && !_isEmpty( modalData.type ) ) ) {
            error = 'Please select a user type';
        } // end - modalData.type

        if ( !( modalData && modalData.status && !_isEmpty( modalData.status ) ) ) {
            error = 'Please select a status';
        } // end - modalData.role

        if ( !( modalData && modalData.email && validateEmail( modalData.email ) ) ) {
            error = 'Please insert a valid email address';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editUser(modalData));
        } // end - error
    }

    handleMassEdit = () => {
        const { modalData, checked } = this.state;
        var error = false;

        if ( !( modalData && modalData.type && !_isEmpty( modalData.type ) ) ) {
            error = 'Please select a user type';
        } // end - modalData.type

        if ( !( modalData && modalData.status && !_isEmpty( modalData.status ) ) ) {
            error = 'Please select a status';
        } // end - modalData.role

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editUsers({
                list: checked,
                data: modalData
            }));
        } // end - error
    }

    handleAction = () => {
        const { modalType } = this.state;
        if ( modalType && modalType == 'new' ) {
            this.handleAddNew();
        } else if ( modalType && modalType == 'edit' ) {
            this.handleEdit();
        } else if ( modalType && modalType == 'massedit' ) {
            this.handleMassEdit();
        } // end - modalType
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteUser(deleteModal.uid));
    }

    getActions = () => {
        const { authData } = this.props;
        const { checked } = this.state;
        let actions = [
            { id: 'addnew', label: 'Add New User', icon: 'fa-plus-circle', onClick: () => this.setState({ openViewModal: true, modalData: { 
                name: '', 
                email: '', 
                type: 'noaccess' 
            }, modalType: 'new' }) },
            {  
                id: 'edit',
                label: 'Edit',
                icon: 'fa-edit',
                disabled: ( checked && isArrayExists( checked ) ? false : true ),
                onClick: () => this.setState({
                    openViewModal: true, modalData: { 
                        status: 'disabled', 
                        type: 'noaccess' 
                    }, modalType: 'massedit'
                })
            }
        ];

        return actions;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { users } = this.props;
        var items = ( users ? cloneCollections( users ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','role'] );
			total = _size( items );
        } // end - searchterms

        // do filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
            items = _filter( items, { region: filterBy } );
            total = _size( items );
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['created_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderViewForm = () => {
        const { regions, roles } = this.props;
        const { modalData, modalType } = this.state;
        return (
        <div>
            { modalType && ( modalType == 'edit' || modalType == 'massedit' ) ? <div style={{ marginTop: '8px' }}><FormSelect 
                label="Status (Required)" 
                name="status" 
                value={( modalData.status || '' )} 
                options={[
                    {value:'active',label: 'Active'},
                    {value:'pending',label: 'Pending'},
                    {value:'disabled',label: 'Disabled'},
                ]}
                onChange={this.handleFormUpdate} /></div> : null }
            { modalType && ( modalType == 'edit' || modalType == 'new' ) ? <FormInput label="Name" name="name" value={( modalData.name || '' )} onChange={this.handleFormUpdate} /> : null }
            { modalType && ( modalType == 'edit' || modalType == 'new' ) ? <FormInput label="Email (Required)" type="email" name="email" value={( modalData.email || '' )} onChange={this.handleFormUpdate} /> : null }
            { modalType ? <div style={{ paddingTop: '10px' }}><FormSelect 
                label="Role (Required)" 
                name="type" 
                value={( modalData.type || '' )} 
                options={[
                    { value: 'noaccess', label: 'No Access' },
                    { value: 'user', label: 'User' },
                    { value: 'admin', label: 'Admin' },
                    { value: 'superadmin', label: 'Super Admin' }
                ]}
                onChange={this.handleFormUpdate} /></div> : null }
        </div>
        );
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms, checked } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Entries first' },
                    { value: 'date-asc', label: 'Oldest Entries first' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' }
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "200px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                    ,
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, modalType, checked, allCheck } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title={ modalType && ( modalType == 'edit' || modalType == 'massedit' ) ? "Edit User" : "Add New User" }
                actionLabel={ modalType && ( modalType == 'edit' || modalType == 'massedit' ) ? "Update" : "Add New" }
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })}
                doAction={this.handleAction}
                contents={this.renderViewForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this user ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                item_key="uid"
                cells={[
                    { id: 'status', label: 'Status', render: (item) => ( item.status && item.status == 'active' ? <SuccessTag>{item.status }</SuccessTag> : ( item.status && item.status == 'pending' ? <AmberTag>{item.status}</AmberTag> : <GreyTag>disabled</GreyTag> ) ) },
                    { id: 'name', label: 'Name', render: (item) => ( <WrapWord>{item.name || ''}</WrapWord> )},
                    { id: 'email', label: 'Email', render: (item) => ( <WrapWord>{item.email || ''}</WrapWord> ) },
                    { id: 'type', label: 'Role', render: (item) => ( item.type && ( item.type === 'user' || item.type === 'admin' || item.type === 'superadmin' ) ? item.type : 'noaccess' ) }
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <InfoButton size="small" onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: 'edit' })}><i className="fa fa-edit"></i>Edit</InfoButton>
                        <ErrorButton size="small" disabled={( item.uid && item.uid == authData.uid ? 'yes' : false )} onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton>
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(Users);