import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';

import { InfoButton, GreyButton } from '../../styles/button';

import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';


class ProductDemos extends React.Component {

    render() {
        return (
        <div>
            
        </div>
        );
    }

}

export default compose(
    connect(),
    withRouter
)(ProductDemos);