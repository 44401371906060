import styled from "styled-components";

export const OpacityComponent = styled.div`
    opacity: ${props => (props.opacity ? props.opacity : 1)};
    pointer-events: ${props => (props.disabled ? "none" : "inherit")};
`;

export const FlexComponent = styled.div`
    display: ${props => (props.inline ? 'inline-flex' : 'flex')};
    flex-direction: ${props => (props.direction ? props.direction : 'row')};
    justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
    align-items: ${props => (props.items ? props.items : 'flex-start')};
    align-content: ${props => (props.content ? props.content : 'flex-start')};
`;

export const FlexColumn = styled.div`
    width: ${props => (props.width ? props.width : '100%')};
`;

export const FullScreenFlex = styled(FlexComponent)`
    height: ${props => (props.height ? props.height : '100vh')};
    width: 100%;
`;

export const FullScreenComponent = styled.div`
    height: ${props => (props.height ? props.height : '100vh')};
    width: 100%;
`;

export const Disabled = styled.div`
    opacity: ${props => (props.opacity ? props.opacity : 1)};
    cursor: default;
    pointer-events: none;
`;


export const Section = styled.div`
    width: 100%;
    background: ${props => (props.background ? props.background : '#fff')};
    padding-top: ${props => (props.paddingTop ? props.paddingTop : '6%')};
    padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '6%')};

    @media (max-width:600px) {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    & > div {
        width: 100%;
        max-width: ${props => (props.width ? props.width : '1110px')};
        margin: 0 auto;
        padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '15px')};
        padding-right: ${props => (props.paddingRight ? props.paddingRight : '15px')};

        @media (max-width:1024px) {
            padding-left: 45px;
            padding-right: 45px;
        }

        @media (max-width:600px) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
`;

export const Spaces = styled.div`
    width: 100%;
    height: ${props => (props.lg ? props.lg : '30px')};
    content: " ";
    
    ${props => (props.sm ? '@media (max-width:1024px) { height: '+props.sm+'; }' : '' )}
    ${props => (props.xs ? '@media (max-width:600px) { height: '+props.xs+'; }' : '' )}
`;