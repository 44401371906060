import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import Products from './products';

import { getProducts } from '../../actions/products';
import { resetRedux } from '../../actions/misc';

class ProductsPage extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        // get lists
        this.props.dispatch(getProducts());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { productsList, randNum } = this.props;

        // once all the data is loaded
        if ( productsList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    componentWillUnmount() {
        // reset
        this.props.dispatch(resetRedux('product'));
    }

    handleRefresh = () => {
        // get list
        this.props.dispatch(getProducts());
        this.setState({ randNum: false });
    } 

    renderContents() {
        const { authData, productsList } = this.props;
        return <Products
            authData={authData}
            products={productsList}
            onRefresh={this.handleRefresh} />;
    }

    render() {
        const { productsList } = this.props;
        const { randNum } = this.state;
        return <AppWrapper 
                title="Products Management"
                maxWidth="1110px"
                onLoad={( !( randNum && productsList ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        productsList: state.products && state.products.products || null,
        randNum: state.products && state.products.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ProductsPage);