import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_PRODUCTS,
	GET_PRODUCT,
	ADD_PRODUCT,
	UPDATE_PRODUCT,
	DELETE_PRODUCT
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getProducts, fb_getProduct, fb_addNewProduct, fb_updateProduct, fb_deleteProduct } from './fb';


export const getProducts = () => {
	return dispatch => {

		fb_getProducts()
		.then( list => {
			dispatch({ type: GET_PRODUCTS, payload: { list } });
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to get products' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const getProduct = (id) => {
	return dispatch => {

		fb_getProduct(id)
		.then( product => {
			dispatch({ type: GET_PRODUCT, payload: product });
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to get product' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const addNewProduct = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addNewProduct(formData)
		.then( results => {
			fb_getProducts()
			.then(list => {
				dispatch({ type: GET_PRODUCTS, payload: { list } });	
				dispatch({ type: ADD_PRODUCT });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Product Added");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new product' ) );
			triggerErrorAlert(errMsg);
		});
	}
}

export const updateProduct = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateProduct(formData)
		.then( results => {
			fb_getProduct(formData.id)
			.then(product => {
				dispatch({ type: GET_PRODUCT, payload: product });	
				dispatch({ type: UPDATE_PRODUCT });
				dispatch(appChangesReset());
				dispatch(toggleLoader(false));
				triggerSuccessAlert("Product Updated");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update product' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteProduct = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteProduct(id)
		.then( results => {
			fb_getProducts()
			.then(list => {
				dispatch({ type: GET_PRODUCTS, payload: { list } });	
				dispatch({ type: DELETE_PRODUCT });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Product Deleted");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete product' ) );
			triggerErrorAlert(errMsg);
		});
	}
}