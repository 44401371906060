import * as firebase from 'firebase/app';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getUsersOptions = (callback) => {

    firebase.database().ref("users").on("value",snapshot => {

        var users = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach(childSnapshot => {
                var user = childSnapshot.val();
                user['id'] = childSnapshot.key;
                users.push(user);
            });
        } // end - snapshot

        callback(users);

    });

}

export const fb_getUsers = () => {
    return new Promise((resolve,reject) => {
        
        firebase.firestore().collection("users").get()
        .then(snapshot => {
        
            var users  = [];
    
            if ( snapshot && !snapshot.empty ) {
                snapshot.forEach( doc => {
                    var val = doc.data();
                    users.push({
                        uid: doc.id,
                        name: val.name || '',
                        email: val.email || '',
                        status: val.status || 'disabled',
                        type: val.type || 'noaccess',
                        created_on: val.created_on || 0
                    })
                });
            } // end - snapshot
            
            resolve(users);
    
        })
        .catch(error => {
            reject(error);
        });
        

    })
}

export const fb_addUser = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'user', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_editUser = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'user', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_editUsers = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'user', action: 'mass_update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteUser = (uid) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'user', action: 'delete', formData: { uid } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}