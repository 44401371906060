import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';
import _trim from 'lodash/trim';

import FormInput from '../../components/FormInput';
import ModalView from '../../components/ModalView';

import { triggerErrorAlert } from '../../helpers/alert';
import { validateEmail } from '../../helpers/validation';

import { FullScreenFlex, FullScreenComponent } from '../../styles/division';
import { Button, InverseButton, IndigoButton, ErrorButton } from '../../styles/button';

import { socialSignIn, triggerEmailSignIn, logout } from '../../actions/auth';

class LoginPage extends React.Component {

    state = {
        openEmailField: false,
        email: ''
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleEmailSignIn = () => {
        const { email } = this.state;
        var error = false;

        if ( !( email && !_isEmpty( email ) && validateEmail( _trim( email ) ) ) )
            error = 'Please enter a valid email address';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(triggerEmailSignIn(email));
        } // end - triggerErrorAlert
        
    }

    handleEmailSignInClicked = event => {
        event.preventDefault();
        this.setState({ openEmailField: true, email: '' });
    }

    handleSocialSignIn = event => {
        event.preventDefault();
        this.props.dispatch(socialSignIn());
    }
    
    handleLogout = event => {
        event.preventDefault();
        this.props.dispatch(logout());
    }

    renderEmailField = () => {
        const { email } = this.state;
        return (
        <div>
            <FormInput label="Enter Your Email Address" type="email" value={( email || '' )} onChange={(newValue) => this.setState({ email: newValue })} />
        </div>
        )
    }

    renderLoginButton = () => {
        return (
        <div style={{ width: "100%", maxWidth: "500px", padding: "0 15px", margin: '0 auto' }}>
            <div>
                <IndigoButton style={{ textTransform: 'uppercase', padding: "10px 45px", width: "100%" }} onClick={this.handleEmailSignInClicked}><i className="fa fa-envelope"></i>Sign-In with Email</IndigoButton>
            </div>
            <div style={{ paddingTop: "20px" }}>
                <ErrorButton style={{ textTransform: 'uppercase', padding: "10px 45px", width: "100%" }} onClick={this.handleSocialSignIn}><i className="fa fa-google"></i>Sign-In with Google Account</ErrorButton>
            </div>
        </div>
        )
    }

    renderIsLoggedIn = () => {
        const { authData } = this.props;
        return (
        <div>
            <Typography variant="body1" align="center" style={{ marginBottom: "20px" }}>
                You're currently logged in as:<br />
                { authData && authData.name && !_isEmpty( authData.name ) ? <strong style={{ fontWeight: "700", display: 'block' }}>{authData.name}</strong> : null }
                { authData && authData.email && !_isEmpty( authData.email ) ? '( ' + authData.email + ' )' : null }
            </Typography>
            <Button style={{ textTransform: 'uppercase', padding: "10px 45px" }} onClick={this.handleLogout}><i className="fa fa-power-off"></i>Logout</Button>
        </div>
        )
    }

    renderVerification = () => {
        return (
        <div>
            
        </div>
        )
    }

    renderContent = () => {
        const { authData } = this.props;
        return (
        <FullScreenComponent style={{ background: 'url("/background.jpg")', backgroundPosition: "center center", backgroundSize: "cover" }}>
            <FullScreenFlex justify="center" items="center" style={{ background: "rgba(0,0,0,0.65)" }}>
                <div style={{ textAlign: 'center', padding: "60px 90px", background: "#f1f1f1", borderRadius: "12px", boxShadow: "0 0 15px 0 rgba(0,0,0,0.25)" }}>
                    <div style={{ margin: "0 auto 45px auto", maxWidth: "300px" }}><img src="/logo.png" style={{ display: "block", width: "100%" }} /></div>
                    { authData ? this.renderIsLoggedIn() : this.renderLoginButton() }
                </div>
            </FullScreenFlex>
        </FullScreenComponent>
        )
    }

    render() {
        const { openEmailField } = this.state;
        return (
        <div>

            {this.renderContent()}

            <ModalView 
                open={openEmailField}
                title={"Sign-In with Email Address"}
                actionLabel={"Get Sign-In Link"}
                doAction={this.handleEmailSignIn}
                contents={this.renderEmailField()}
                onClose={() => this.setState({ openEmailField: false, email: '' })} />

        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(LoginPage);