import * as firebase from 'firebase/app';
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	IS_LOGGED_IN,
	AUTH_LOGIN,
	AUTH_LOGOUT,
	AUTH_GET_PROFILE,
	AUTH_EDIT_PROFILE,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleLoader, toggleModalProcessing } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { googleSignIn, logoutUser, onAuth, sendEmailSignInLink, verifyEmailSignInLink, fb_getProfile, fb_updateProfile, getCurrentUser } from './fb';
import { appChangesReset } from '../misc';

export const isUserLoggedIn = () => {
	return dispatch => {

        firebase.auth().onAuthStateChanged(function(userToken) {
            if (userToken) {
				onAuth(userToken)
				.then(user => {
					dispatch({
						type: IS_LOGGED_IN,
						payload: user
					});
				})
				.catch(error => {
					var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to sign in' ) );
					dispatch({ type: AUTH_LOGOUT });
					triggerErrorAlert(errMsg);
				});
            } else {
                dispatch({ type: AUTH_LOGOUT });
            }
        }); 

	}
}

export const socialSignIn = () => {
	return dispatch => {
		dispatch(toggleLoader(true));

		googleSignIn()
		.then( user => {
			dispatch({
				type: AUTH_LOGIN,
				payload: user
			});
			dispatch(toggleLoader(false));
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to sign in' ) );
			dispatch(toggleLoader(false));
			triggerErrorAlert( errMsg );
		});

	}
}

export const triggerEmailSignIn = (email) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		sendEmailSignInLink(email)
		.then( results => {
			// updated
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("We sent you an email with your sign-in link. Please check your inbox now",4000);
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to trigger email sign-in link' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const verifySignInLink = (history) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		verifyEmailSignInLink()
		.then( user => {
			dispatch({
				type: AUTH_LOGIN,
				payload: user
			});
			dispatch(toggleLoader(false));
			history.push('/dashboard');
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to sign in' ) );
			dispatch(toggleLoader(false));
			triggerErrorAlert( errMsg );
		});

	}
}

export const logout = () => {
	return dispatch => {
		dispatch(toggleLoader(true));

		logoutUser()
		.then(() => {
			dispatch({ type: AUTH_LOGOUT });
			dispatch(toggleLoader(false));
		});

	}
}

export const getProfile = () => {
	return dispatch => {
		
		fb_getProfile()
		.then( user => {
			dispatch({
				type: AUTH_GET_PROFILE,
				payload: user
			});
		})
		.catch(error => {
			triggerErrorAlert( error.message );
		});

	}
}

export const updateProfile = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateProfile(formData)
		.then( results => {
			fb_getProfile()
			.then(user => {
				// push to list
				dispatch({ type: AUTH_GET_PROFILE, payload: user });

				setTimeout(() => {

					const userToken = getCurrentUser();
					onAuth(userToken)
					.then(user => {
						// update auth data
						dispatch({ type: IS_LOGGED_IN, payload: user });

						// updated
						dispatch({ type: AUTH_EDIT_PROFILE });
						dispatch(toggleLoader(false));
						dispatch(appChangesReset());
						triggerSuccessAlert("Profile Updated");
					})

				},1500);

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update contact' ) );
			triggerErrorAlert(errMsg);
		});

	}
}