import * as firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getSandbox = () => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'sandbox', action: 'get' })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_createSandbox = () => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'sandbox', action: 'create' })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}


export const fb_getSandboxes = () => {
    return new Promise((resolve,reject) => {

        firebase.firestore().collection("sites").get()
        .then(snapshot => {
        
            var list  = [];
    
            if ( snapshot && !snapshot.empty ) {
                snapshot.forEach( doc => {
                    list.push(doc.data());
                });
            } // end - snapshot
            
            resolve(list);
    
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_editSandbox = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'sandbox', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_editSandboxes = (list,formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'sandbox', action: 'mass_update', formData: { 
            list,
            data: ( formData || false )
        } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_cloneSandbox = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'sandbox', action: 'clone', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteSandbox = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'sandbox', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}