import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';


class OpacityLoader extends React.Component {

    render() {
        const { open } = this.props;
        return (
        <Dialog fullScreen open={open} style={{ zIndex: '999999' }} BackdropProps={{
            style: {
                background: 'rgba(255,255,255,0.7)'
            }
        }}>
            <LinearProgress thickness={6} color="primary" style={{ zIndex: '9999999' }} />
        </Dialog>
        )
    }

}

export default OpacityLoader;