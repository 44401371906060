import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _upperFirst from 'lodash/upperFirst';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import ButtonActions from '../../components/ButtonActions';
import FormSelect from '../../components/FormSelect';
import FormInput from '../../components/FormInput';
import FormInputCopy from '../../components/FormInputCopy';

import { InfoButton, ErrorButton, ButtonGroup, SuccessButton, GreyButton, InverseButton, IndigoButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';
import { SuccessTag, AmberTag, GreyTag } from '../../styles/tag';

import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';

import { editSandbox, editSandboxes, deleteSandbox, cloneSandbox } from '../../actions/sandboxes';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top',
        fontWeight: '400'
    }
});


class Sandboxes extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleFormUpdate = (newValue,key) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    // handleAddNew = () => {
    //     const { modalData } = this.state;
    //     var error = false;

    //     if ( !( modalData && modalData.type && !_isEmpty( modalData.type ) ) ) {
    //         error = 'Please select a user type';
    //     } // end - modalData.type

    //     if ( !( modalData && modalData.email && validateEmail( modalData.email ) ) ) {
    //         error = 'Please insert a valid email address';
    //     } // end - modalData.email

    //     if ( error ) {
    //         triggerErrorAlert(error);
    //     } else {
    //         this.props.dispatch(addUser(modalData));
    //     } // end - error
    // }

    handleSiteClone = site => event => {
        event.preventDefault();
        if ( site.id && !_isEmpty(site.id) ) {
            this.props.dispatch(cloneSandbox(site.id));
        } else {
            triggerErrorAlert("Invalid Site ID");
        } // end - site.id
    }

    handleEdit = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.type && !_isEmpty( modalData.type ) ) ) {
            error = 'Please select a user type';
        } // end - modalData.type

        if ( !( modalData && modalData.status && !_isEmpty( modalData.status ) ) ) {
            error = 'Please select a status';
        } // end - modalData.role

        if ( !( modalData && modalData.email && validateEmail( modalData.email ) ) ) {
            error = 'Please insert a valid email address';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editSandbox(modalData));
        } // end - error
    }

    handleMassEdit = () => {
        const { modalData, checked } = this.state;
        var error = false;

        if ( !( modalData && modalData.type && !_isEmpty( modalData.type ) ) ) {
            error = 'Please select a user type';
        } // end - modalData.type

        if ( !( modalData && modalData.status && !_isEmpty( modalData.status ) ) ) {
            error = 'Please select a status';
        } // end - modalData.role

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editSandboxes(checked,modalData));
        } // end - error
    }

    handleAction = () => {
        const { modalType } = this.state;
        if ( modalType && modalType == 'new' ) {
            this.handleAddNew();
        } else if ( modalType && modalType == 'edit' ) {
            this.handleEdit();
        } else if ( modalType && modalType == 'massedit' ) {
            this.handleMassEdit();
        } // end - modalType
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteSandbox(deleteModal.id));
    }

    getActions = () => {
        const { authData } = this.props;
        const { checked } = this.state;
        let actions = [
            // { id: 'addnew', label: 'Add New Sandbox', icon: 'fa-plus-circle', onClick: () => this.setState({ openViewModal: true, modalData: { 
            //     name: '', 
            //     email: '', 
            //     type: 'noaccess' 
            // }, modalType: 'new' }) },
            {  
                id: 'edit',
                label: 'Edit',
                icon: 'fa-edit',
                disabled: ( checked && isArrayExists( checked ) ? false : true ),
                onClick: () => this.setState({
                    openViewModal: true, modalData: { 
                        status: 'disabled', 
                        type: 'noaccess' 
                    }, modalType: 'massedit'
                })
            }
        ];

        return actions;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { sandboxes } = this.props;
        var items = ( sandboxes ? cloneCollections( sandboxes ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['domainName'] );
			total = _size( items );
        } // end - searchterms

        // do filter
        // if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
        //     items = _filter( items, { region: filterBy } );
        //     total = _size( items );
        // }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, (i) => ( i && i.created_on && i.created_on.seconds ? i.created_on.seconds : 0 ));
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, (i) => ( i && i.created_on && i.created_on.seconds ? i.created_on.seconds : 0 ));
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['domainName'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['domainName'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderEditForm = () => {
        const { modalData, modalType } = this.state;
        return (
        <div>
            {/* { modalType && ( modalType == 'edit' || modalType == 'massedit' ) ? <div style={{ marginTop: '8px' }}><FormSelect 
                label="Status (Required)" 
                name="status" 
                value={( modalData.status || '' )} 
                options={[
                    {value:'active',label: 'Active'},
                    {value:'pending',label: 'Pending'},
                    {value:'disabled',label: 'Disabled'},
                ]}
                onChange={this.handleFormUpdate} /></div> : null }
            { modalType && ( modalType == 'edit' || modalType == 'new' ) ? <FormInput label="Name" name="name" value={( modalData.name || '' )} onChange={this.handleFormUpdate} /> : null }
            { modalType && ( modalType == 'edit' || modalType == 'new' ) ? <FormInput label="Email (Required)" type="email" name="email" value={( modalData.email || '' )} onChange={this.handleFormUpdate} /> : null }
            { modalType ? <div style={{ paddingTop: '10px' }}><FormSelect 
                label="Type (Required)" 
                name="type" 
                value={( modalData.type || '' )} 
                options={[
                    { value: 'noaccess', label: 'No Access' },
                    { value: 'user', label: 'User' },
                    { value: 'admin', label: 'Admin' },
                    { value: 'superadmin', label: 'Super Admin' }
                ]}
                onChange={this.handleFormUpdate} /></div> : null } */}
        </div>
        );
    }

    renderViewForm = () => {
        const { modalData } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormInputCopy label="Sandbox URL" value={( modalData && modalData.domainName && !_isEmpty( modalData.domainName ) ? 'https://'+modalData.domainName : '' )} labelWidth={100} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputCopy label="WP Admin Username" type="email" value={( modalData && modalData.wp_admin_username || '' )} labelWidth={120} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputCopy label="WP Admin Email" type="email" value={( modalData && modalData.wp_admin_email || '' )} labelWidth={120} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputCopy label="WP Admin Password" type="password" value={( modalData && modalData.wp_admin_pwd || '' )} labelWidth={120} />
                </Grid>
            </Grid>
        </div>
        );
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms, checked } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Entries first' },
                    { value: 'date-asc', label: 'Oldest Entries first' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' }
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "200px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                    ,
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { users } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, modalType, checked, allCheck } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title={( modalType && modalType == 'view' ? 'View Sandbox' : ( modalType && ( modalType == 'edit' || modalType == 'massedit' ) ? "Edit Sandbox" : "Add New Sandbox" ) )}
                actionLabel={ modalType && ( modalType == 'edit' || modalType == 'massedit' ) ? "Update" : "Add New" }
                noAction={ modalType && modalType == 'view' ? true : false }
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })}
                doAction={this.handleAction}
                contents={ modalType && modalType == 'view' ? this.renderViewForm() : this.renderEditForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this sandbox ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'id', label: 'ID', render: (item) => ( <WrapWord>{item.id || ''}</WrapWord> )},
                    { id: 'domainName', label: 'URL', render: (item) => ( item.domainName && !_isEmpty( item.domainName ) ? <FormInputCopy value={'https://'+item.domainName} labelWidth={0} /> : '---' )},
                    { id: 'type', label: 'Type', render: (item) => ( <WrapWord>{item.type && !_isEmpty( item.type ) ? _upperFirst( item.type ) : '' }</WrapWord> )},
                    { id: 'uid', label: 'Created By', render: (item) => {
                        let selected = ( item.uid && !_isEmpty( item.uid ) ? _find( users, { uid: item.uid } ) : false );
                        return <WrapWord>{( selected && selected.name && !_isEmpty( selected.name ) ? selected.name : ( selected && selected.email && !_isEmpty( selected.email ) ? selected.email : '' ) )}</WrapWord>
                    }},
                    { id: 'created_on', label: 'Created On', render: (item) => ( item.created_on && item.created_on.seconds ? getMomentTime( (item.created_on.seconds*1000), 'YYYY-MM-DD' ) : '---' ) },

                ]}
                actionStyles={{ width: "20%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <SuccessButton size="small" onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: 'view' })}><i className="fa fa-search"></i>View</SuccessButton>
                        <IndigoButton size="small" onClick={this.handleSiteClone(item)}><i className="fa fa-clone"></i>Clone</IndigoButton>
                        <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton>
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(Sandboxes);