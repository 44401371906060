import _random from 'lodash/random';

import { 
    GET_USERS,
    GET_USERS_OPTIONS,
    ADD_USER,
    EDIT_USER,
    EDIT_USERS,
    DELETE_USER,
    SYNC_USERS
} from '../actions/types';

const initialState = {
    users: null,
    rand: false
};

export const users = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_USERS:
            return {
                ...state,
                users: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_USERS_OPTIONS:
            return {
                ...state,
                users_options: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case ADD_USER:
        case EDIT_USER:
        case EDIT_USERS:
        case DELETE_USER:
        case SYNC_USERS:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        default:
            return state;
    }
}