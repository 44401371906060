import _now from 'lodash/now';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _last from 'lodash/last';
import _size from 'lodash/size';
import _endsWith from 'lodash/endsWith';
import moment from 'moment';

import { isTimeStamp, isArrayExists } from './validation';
import { replaceAll } from './data';

/* convert value into date using moment */
export const getMomentTime = ( value, format , offset ) => {
    var utc = ( offset ? offset : 8 );
    return ( value ? moment(value).utcOffset(utc).format(format) : '' );
}

/* convert now into date using moment */
export const getMomentNow = ( format , offset ) => {
    var utc = ( offset ? offset : 8 );
    return moment().utcOffset(utc).format(format);
}

/* convert use moment to convert whatever value to timestamp */
export const getMomentTimestamp = ( value , offset ) => {
    var utc = ( offset ? offset : 8 );
    return moment(value).utcOffset(utc).valueOf();
}

/* compare time from x using moment */
export const compareTimeFromMoment = ( value, offset ) => {
    var utc = ( offset ? offset : 8 );
    return ( value ? moment().utcOffset(utc).from(value, true) : '' );
}