import React from 'react';
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import styled from "styled-components";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import _isEmpty from 'lodash/isEmpty';

import Header from '../Header';
import TopBar from '../TopBar';
import DotsLoader from '../DotsLoader';

import { FlexColumn } from '../../styles/division';
import { InfoButton, GreyButton } from '../../styles/button';
import theme from '../../theme';

import { isArrayExists } from '../../helpers/validation';

import { toggleLoader } from '../../actions/global';
import { appChangesReset } from '../../actions/misc';

const MainWrapper = styled.div`
    min-height: 100vh;
    background: #f5f9fc;
`;

const ContentOuterWrapper = styled.div`
    margin-top: 66px;
    padding: 0px 30px 30px 30px;
`;

const ContentWrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    max-width: ${props => (props.maxWidth ? props.maxWidth : '1440px')};
    padding: 15px 0px;
    margin: 0px auto;
`;

class AppWrapper extends React.Component {

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleClick = (url,event) => {
        const { history, changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            history.push(url);
        }
    }

    getHeaderProps = () => {
        const { headerprops } = this.props;
        return ( headerprops && !_isEmpty( headerprops ) ? headerprops : {
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center"
        } );
    }

    renderButtons() {
        const { buttons } = this.props;
        const btnStyle = {
            textAlign: 'center',
            minWidth: '200px',
            padding: "10px 5px"
        };
        return (
        <FlexColumn>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
            {buttons.map((button,index) => {
                if ( button.active ) {
                    return <InfoButton key={index} style={btnStyle} onClick={event => event.preventDefault()}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</InfoButton>
                } else {
                    return <GreyButton key={index} style={btnStyle} onClick={this.handleClick.bind(this,button.url)}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</GreyButton>
                }
            })}
            </Box>
        </FlexColumn>
        )
    }

    renderSubTitle() {
        const { subtitle } = this.props;
        return <Typography variant="h5" color="primary" style={{ fontWeight: "700", textTransform: "uppercase", marginBottom: "10px" }}>{subtitle}</Typography>;
    }

    renderTitle() {
        const { title } = this.props;
        return <Typography variant="h3" color="textPrimary" style={{ fontWeight: "700" }}>{title}</Typography>;
    }

    renderContent = () => {
        const { transition, onLoad } = this.props;
        if ( transition && transition == 'none' ) {
            return <div style={{ paddingTop: "30px" }}>{ this.props.contents || '' }</div>;
        } else if ( transition && transition == 'zoom' ) {
            return <Zoom in={!onLoad} timeout={350}><div style={{ paddingTop: "30px" }}>{ this.props.contents || '' }</div></Zoom>;
        } else if ( transition && transition == 'grow' ) {
            return <Grow in={!onLoad} timeout={350}><div style={{ paddingTop: "30px" }}>{ this.props.contents || '' }</div></Grow>;
        } else {
            return <Fade in={!onLoad} timeout={350}><div style={{ paddingTop: "30px" }}>{ this.props.contents || '' }</div></Fade>;
        }
    }

    renderLoader() {
        return <DotsLoader style={{ paddingTop: "60px", paddingBottom: "60px" }} />;
    }

    render() {
        const { hideTopBar, back, breadcrumbs, title, subtitle, buttons, onLoad, maxWidth } = this.props;
        return (
        <MainWrapper>
            <Header />
            <ContentOuterWrapper>
                { hideTopBar || !( back && breadcrumbs && isArrayExists( breadcrumbs ) ) ? <div style={{ height: "70px", opacity: '0' }}>-</div> : <TopBar {...this.props} /> }
                <ContentWrapper maxWidth={( maxWidth || null )}>
                    <Box {...this.getHeaderProps()}>
                        <FlexColumn>
                            { subtitle && !_isEmpty( subtitle ) ? this.renderSubTitle() : null }
                            { title && !_isEmpty( title ) ? this.renderTitle() : null }
                        </FlexColumn>
                        { buttons && isArrayExists( buttons ) ? this.renderButtons() : null }
                    </Box>
                    { onLoad ? this.renderLoader() : this.renderContent()}
                </ContentWrapper>
            </ContentOuterWrapper>
        </MainWrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        changesNotSaved: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(AppWrapper);