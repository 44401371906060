import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';

import FormInputCopy from '../../components/FormInputCopy';

import { InfoButton, GreyButton } from '../../styles/button';
import { FormBox } from '../../styles/form';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';

import { productSchema } from '../../schemas/product';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class ProductSandBox extends React.Component {

    render() {
        const { classes, product } = this.props;
        return (
        <Paper elevation={2} style={{ background: '#fff', padding: '30px 20px' }}>

            <FormBox>
                <Typography variant="h4" className={classes.boxheading}>SandBox Details</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormInputCopy label="Sandbox URL" value={( product && product.sandbox && product.sandbox.url || '' )} labelWidth={100} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputCopy label="Email" type="email" value={( product && product.sandbox && product.sandbox.email || '' )} labelWidth={50} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputCopy label="Password" type="password" value={( product && product.sandbox && product.sandbox.password || '' )} labelWidth={80} />   
                    </Grid>
                </Grid>
            </FormBox>


        </Paper>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(ProductSandBox);