import React from 'react';
import styled from "styled-components";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { triggerSuccessAlert } from '../../helpers/alert';
import theme from '../../theme';

class FormInputCopy extends React.Component {

    handleChange = () => {

    }

    onCopied = () => {
        triggerSuccessAlert('Copied to Clipboard');
    }

    render() {
        const { type, label, value, labelWidth } = this.props;
        return (
        <FormControl fullWidth={true} variant="outlined">
            <InputLabel>{label}</InputLabel>
            <OutlinedInput
                value={value}
                type={( type || 'text' )}
                onChange={this.handleChange}
                endAdornment={
                <InputAdornment position="end">
                    <Tooltip title="Copy"><CopyToClipboard text={value}
                        onCopy={this.onCopied}>
                        <FileCopyOutlinedIcon style={{ cursor: 'pointer' }} />
                    </CopyToClipboard></Tooltip>
                </InputAdornment>
                }
                labelWidth={( labelWidth || 60 )}
            />
        </FormControl>
        )
    }

}

export default FormInputCopy