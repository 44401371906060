export const SAMPLE_ACTION = 'sample_action';

/* auth */
export const IS_LOGGED_IN = 'is_logged_in';
export const AUTH_LOGIN = 'auth_login';
export const AUTH_LOGOUT = 'auth_logout';
export const AUTH_EDIT_PROFILE = 'auth_edit_profile';
export const AUTH_GET_PROFILE = 'auth_get_profile';

/* sandbox actions */
export const GET_SANDBOXES = 'get_sandboxes';
export const GET_SANDBOX = 'get_sandbox';
export const CREATE_SANDBOX = 'create_sandbox';
export const ADD_SANDBOX = 'add_sandbox';
export const UPDATE_SANDBOX = 'update_sandbox';
export const UPDATE_SANDBOXES = 'update_sandboxes';
export const DELETE_SANDBOX = 'delete_sandbox';

/* product actions */
export const GET_PRODUCTS = 'get_products';
export const GET_PRODUCT = 'get_product';
export const ADD_PRODUCT = 'add_product';
export const UPDATE_PRODUCT = 'update_product';
export const DELETE_PRODUCT = 'delete_product';
export const RESET_PRODUCT = 'reset_product';

/* users actions */
export const GET_USERS = 'get_users';
export const GET_USERS_OPTIONS = 'get_users_options';
export const ADD_USER = 'add_user';
export const EDIT_USER = 'edit_user';
export const EDIT_USERS = 'edit_users';
export const DELETE_USER = 'delete_user';
export const SYNC_USERS = 'sync_users';



/* mass actions */
export const DO_MASS_ACTION = 'do_mass_action';
export const RESET_MASS_ACTION = 'reset_mass_action';


/* maintenance actions */


/* global actions */
export const GLOBAL_MODAL_PROCESSING = 'global_modal_processing';
export const GLOBAL_MODAL_DELETING = 'global_modal_deleting';
export const GLOBAL_ERROR_TRIGGER = 'global_error_trigger';
export const GLOBAL_LOADER_START = 'global_loader_start';
export const GLOBAL_LOADER_END = 'global_loader_end';
export const GLOBAL_SNACKBAR_TRIGGER = 'global_snackbar_trigger';

/* MISC actions */
export const MISC_CHANGES_SAVED = 'misc_changes_saved';
export const MISC_CHANGES_RESET = 'misc_changes_reset';
export const MISC_CHANGES_NOT_SAVED = 'misc_changes_not_saved';
