import React from 'react';
import ReactDOM from 'react-dom';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

import './reset.css';
import './styles.css';
import App from './app';
import * as serviceWorker from './serviceWorker';

// init fb
firebase.initializeApp({
    apiKey: "AIzaSyAJZo9RfUW2BiW3UL2bD80Wd9OHpRx5c6c",
    authDomain: "mojofywp-hosting.firebaseapp.com",
    databaseURL: "https://mojofywp-hosting.firebaseio.com",
    projectId: "mojofywp-hosting",
    storageBucket: "mojofywp-hosting.appspot.com",
    messagingSenderId: "763679623012",
    appId: "1:763679623012:web:35caf063f6bcff0fab1c71",
    measurementId: "G-D7Z83HB2M1"
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
