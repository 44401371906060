/*
This file contains all constants in the app.
*/

const api_env = 'live';
export const DEV_MODE = ( api_env && api_env === 'live' ? false : true );

// firebase related
const FIREBASE_SERVE = true;
export const FIREBASE_API = 'https://mojofywp-hosting.firebaseio.com/';
export const FIREBASE_CLOUD_API = ( FIREBASE_SERVE && DEV_MODE ? 'http://localhost:5000/mojofywp-hosting/us-central1/' : 'https://us-central1-mojofywp-hosting.cloudfunctions.net' );
export const FIREBASE_HOSTING_URL = ( DEV_MODE ? 'http://localhost:3000/' : 'https://hosting.mojofywp.com/' );
//export const FIREBASE_HOSTING_URL = 'http://localhost:3000/';

// list of access rights
export const ACCESS_RIGHTS = [
	{ id: 'acr', label: 'Accounts (Read)', width: 6 },
    { id: 'acw', label: 'Accounts (Write)', width: 6 }
];


// options
export const ENTRIES_OPTIONS = [
	{ value: 5, label: '5' },
	{ value: 20, label: '20' },
	{ value: 40, label: '40' },	
	{ value: 60, label: '60' },
	{ value: 80, label: '80' },
	{ value: 100, label: '100' },
	{ value: 200, label: '200' },
	{ value: 500, label: '500' }
];

export const MONTH_OPTIONS = [
	{ value: "01", label: 'Jan' },
	{ value: "02", label: 'Feb' },
	{ value: "03", label: 'Mar' },
	{ value: "04", label: 'April' },
	{ value: "05", label: 'May' },
	{ value: "06", label: 'June' },
	{ value: "07", label: 'July' },
	{ value: "08", label: 'Aug' },
	{ value: "09", label: 'Sept' },
	{ value: "10", label: 'Oct' },
	{ value: "11", label: 'Nov' },
	{ value: "12", label: 'Dec' }
];

export const GENDER_OPTIONS = [{ value: '', label: 'Select Gender' },{ value: 'male', label: 'Male' },{ value: 'female', label: 'Female' }];
export const YES_NO_OPTIONS = [{ value: 'yes', label: 'Yes' },{ value: 'no', label: 'No' }];