import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import DotsLoader from '../../components/DotsLoader';

import { FullScreenFlex, FullScreenComponent } from '../../styles/division';

import { verifySignInLink } from '../../actions/auth';

class emailSignInPage extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        const { history, dispatch } = this.props;
        dispatch(verifySignInLink(history));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    componentWillUnmount() {
        
    }

    renderContents() {
        return (
        <div></div>
        );
    }

    render() {
        const { randNum } = this.state;
        return (
        <FullScreenComponent style={{ background: 'url("/background.jpg")', backgroundPosition: "center center", backgroundSize: "cover" }}>
            <FullScreenFlex justify="center" items="center" style={{ background: "rgba(0,0,0,0.65)" }}>
                <div style={{ textAlign: 'center', padding: "60px 90px", background: "#f1f1f1", borderRadius: "12px", boxShadow: "0 0 15px 0 rgba(0,0,0,0.25)" }}>
                    <DotsLoader messagePosition="bottom" message="Verifying..." />
                </div>
            </FullScreenFlex>
        </FullScreenComponent>
        );
    }

}

export default compose(
    connect(),
    withRouter
)(emailSignInPage);