import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { ThemeProvider } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

// import styles 
import theme from './theme';
import { OpacityComponent } from './styles/division';

// import components
import ScreenLoader from './components/ScreenLoader';
import OpacityLoader from './components/OpacityLoader';
import RouterModifier from './components/RouterModifier';

// import pages
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/Login';
import emailSignInPage from './pages/emailSignInPage';
import UsersPage from './pages/UsersPage';
import SandboxesPage from './pages/SandboxesPage';
import AuthProfilePage from './pages/AuthProfilePage';
import ProductsPage from './pages/ProductsPage';
import ProductPage from './pages/ProductPage';

// import actions
import { isUserLoggedIn } from './actions/auth';

/* User based routes */
const PublicRoute = ({ component, authData, ...rest }) => {
    return (
        <Route {...rest} render={ props => 
            authData && authData.type && ( authData.type === 'user' || authData.type === 'admin' || authData.type === 'superadmin' ) ? (
                <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            ) : (
                React.createElement(component,props)
            )
        } />
    )
}

const UserRoute = ({ component, authData, accessRights, ...rest }) => {
    return (
        <Route {...rest} render={ props => 
            authData ? ( authData.type && ( authData.type === 'user' || authData.type === 'admin' || authData.type === 'superadmin' ) ? 
                React.createElement(component,props) : 
                <Redirect to={{ pathname: '/noaccess', state: { from: props.location } }} />
            ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        } />
    )
}

const AdminRoute = ({ component, authData, ...rest }) => {
    return (
        <Route {...rest} render={ props => 
            authData && authData.type && ( authData.type == 'admin' || authData.type == 'superadmin' ) ? (
                React.createElement(component,props)
            ) : (
                <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            )
        } />
    )
}

const SuperAdminRoute = ({ component, authData, ...rest }) => {
    return (
        <Route {...rest} render={ props => 
            authData && authData.type && ( authData.type == 'superadmin' ) ? (
                React.createElement(component,props)
            ) : (
                <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            )
        } />
    )
}

/* App Structure */
class Structure extends React.Component {
    state = {
        authRand: false,
        dataLoaded: false
    }

    componentDidMount() {
        // call for auth listener
        this.props.dispatch(isUserLoggedIn());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { authRand } = this.props;
        if ( authRand && !this.state.authRand ) {
            this.setState({ dataLoaded: true, authRand });
        }
    }


    renderLoaderLabel() {
        const { loader_label } = this.props;
        return (<div>{ loader_label || 'Loading...' }</div>);
    }
    
    renderStructure() {
        const { loader, authData } = this.props;
        return (
        <React.Fragment>
            { loader ? <OpacityLoader open={loader} /> : null }
            <BrowserRouter>
                <RouterModifier>
                <Switch>
                    <UserRoute path="/dashboard" component={Dashboard} authData={authData} />

                    <UserRoute path="/auth/profile" component={AuthProfilePage} authData={authData} />
                    <UserRoute path="/auth" component={AuthProfilePage} authData={authData} />

                    <SuperAdminRoute path="/users" component={UsersPage} authData={authData} />
                    <SuperAdminRoute path="/sandboxes" component={SandboxesPage} authData={authData} />

                    <SuperAdminRoute path="/products/:product_id" component={ProductPage} authData={authData} />
                    <SuperAdminRoute path="/products" component={ProductsPage} authData={authData} />

                    <PublicRoute path="/emailSignIn" component={emailSignInPage} authData={authData} />
                    <PublicRoute path="/login" component={LoginPage} authData={authData} />
                    <PublicRoute path="/noaccess" component={LoginPage} authData={authData} />

                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    <Route exact path="**" render={() => <Redirect to="/dashboard" />} />

                </Switch>
                </RouterModifier>
            </BrowserRouter>
        </React.Fragment>
        );
    }

    render() {
        const { dataLoaded } = this.state;
        return (
        <ThemeProvider theme={theme}>
            {(dataLoaded ? this.renderStructure() : <ScreenLoader /> )}
        </ThemeProvider> 
        );
    }
}

const mapStateToProps = state => {
    return {
        authData: state.auth.user,
        authRand: state.auth.rand,
        loader: state.global.loader,
        loader_label: state.global.loader_label
    };
};

export default connect(mapStateToProps)(Structure);
