import Alert from 'react-s-alert';

import {
	MISC_CHANGES_NOT_SAVED,
	MISC_CHANGES_RESET,
	RESET_MASS_ACTION,
	RESET_PRODUCT
} from './types';

export const appChangesMade = () => {
	return dispatch => {
  		dispatch({ type: MISC_CHANGES_NOT_SAVED });
	}
}

export const appChangesReset = () => {
	return dispatch => {
  		dispatch({ type: MISC_CHANGES_RESET });
	}
}

export const resetRedux = (type) => {
	return dispatch => {
		switch( type ) {
			case 'mass_action':
				setTimeout(() => {
					dispatch({ type: RESET_MASS_ACTION });
				}, 150);
				break;
			case 'product':
				setTimeout(() => {
					dispatch({ type: RESET_PRODUCT });
				}, 150);
				break;
		}
	}
}