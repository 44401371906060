import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import Sandboxes from './sandboxes';

import { cloneCollections } from '../../helpers/data';

import { getUsersOptions } from '../../actions/users';
import { getSandboxes } from '../../actions/sandboxes';

class SandboxesPage extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        // get lists
        this.props.dispatch(getSandboxes());

        // get users list
        this.props.dispatch(getUsersOptions());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { SandboxList, usersList, randNum } = this.props;

        // once all the data is loaded
        if ( SandboxList && usersList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    handleRefresh = () => {
        // get users
        this.props.dispatch(getSandboxes());
        this.setState({ randNum: false });
    } 

    renderContents() {
        const { SandboxList, usersList, authData } = this.props;
        return (
        <Sandboxes 
            sandboxes={SandboxList} 
            users={usersList}
            authData={authData}
            onRefresh={this.handleRefresh} />
        );
    }

    render() {
        const { SandboxList } = this.props;
        const { randNum } = this.state;
        return <AppWrapper 
                title="Sandbox Management"
                onLoad={( !( SandboxList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        SandboxList: state.sandboxes && state.sandboxes.sandboxes || null,
        randNum: state.sandboxes && state.sandboxes.rand || null,
        usersList: state.users && state.users.users_options || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(SandboxesPage);