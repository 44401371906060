import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import Settings from './settings';
import Sandbox from './sandbox';
import DemoLink from './demo_link';
import Demos from './demos';

import { InfoButton, GreyButton } from '../../styles/button';
import { Spaces } from '../../styles/division';

import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getProduct, updateProduct } from '../../actions/products';
import { resetRedux, appChangesMade } from '../../actions/misc';

import { productSchema } from '../../schemas/product';

class ProductPage extends React.Component {

    state = {
        product: false,
        randNum: false
    };

    componentDidMount() {
        const { product_id } = this.props.match.params;
        // get product
        this.props.dispatch(getProduct(product_id));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentProduct, randNum } = this.props;

        // for account
        if ( currentProduct && randNum && randNum !== this.state.randNum ) {
            this.setState({ product: cloneCollections( currentProduct ), randNum });
        } // end - currentProduct
    }

    componentWillUnmount() {
        // reset
        this.props.dispatch(resetRedux('product'));
    }

    handleSaveChanges = () => {
        const { product } = this.state;
        var error = false,
            formData = {};

        // do error check
        productSchema.forEach(schema => {
            formData[schema.id] = ( product && product[schema.id] ? cloneCollections( product[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateProduct(formData));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ product: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    renderContents() {
        const { product } = this.state;
        return (
        <div>
            
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <Settings 
                        product={product}
                        onUpdate={this.handleFormUpdate}
                        onSave={this.handleSaveChanges} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Sandbox 
                        product={product} />
                    <Spaces xs="20px" />
                    <DemoLink
                        product={product} />    
                </Grid>
                <Grid item xs={12}>
                    <Demos 
                        product={product} />
                </Grid>
            </Grid>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { currentProduct } = this.props;
        const { randNum } = this.state;
        return <AppWrapper 
                title={( currentProduct && currentProduct.name || '' )}
                subtitle="Products Management"
                back="/products"
                breadcrumbs={[
                    { url: '/products', label: 'Products Management' },
                    { label: 'Edit' }
                ]}
                maxWidth="1110px"
                onLoad={( !( randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentProduct: state.products && state.products.product || null,
        randNum: state.products && state.products.rand || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ProductPage);