import _random from 'lodash/random';

// import { 
//     M_GET_REGIONS,
//     M_REGIONS_UPDATED,
//     M_GET_ROLES,
//     M_ROLES_UPDATED
// } from '../actions/types';

const initialState = {
    regions: null,
    roles: null,
    rand: false
};

export const maintenance = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        // case M_GET_REGIONS: return { ...state, regions: ( payload.list || [] ), rand: _random(1,9999) };
        // case M_GET_ROLES: return { ...state, roles: ( payload.list || [] ), rand: _random(1,9999) };

        // case M_REGIONS_UPDATED:
        // case M_ROLES_UPDATED:
        //     return { ...state, rand: _random(1,9999) };
        default:
            return state;
    }
}