import _endsWith from 'lodash/endsWith';
import _replace from 'lodash/replace';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _isUndefined from 'lodash/isUndefined';
import _isInteger from 'lodash/isInteger';
import _isNaN from 'lodash/isNaN';
import _find from 'lodash/find';

export const validate = function( value, type ) {
	var returnData = false;

	switch( type ) {
		case 'alphanumeric': returnData = ( value && isAlphanumeric(value) ? value : false ); break;
		case 'numeric': returnData = ( value && isNumeric(value) ? value : false ); break;
		case 'integer': returnData = ( value && isInteger(value) ? value : false ); break;
		case 'timestamp': returnData = ( value && isTimeStamp(value) ? value : false ); break;
		case 'email': returnData = ( value && validateEmail(value) ? value : false ); break;
		case 'phone': returnData = ( value && isPhoneNumber(value) ? value : false ); break;
		case 'string_id': returnData = ( value && isStringID(value) ? value : false ); break;
		case 'numeric_id': returnData = ( value && isNumericID(value) ? value : false ); break;
		case 'key': returnData = ( value && isKey(value) ? value : false ); break;
		case 'is_nan': returnData = ( value && isNan(value,false) ? value : false ); break;
	}

	return returnData;
}

// validate email
export const validateEmail = function(email) {
	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

// check whether string provided is valid phone number
export const isPhoneNumber = function(string) {
	var re = /^\+?([0-9 -]+)$/;
	return re.test(string);
}

// check whether string provided is valid string id
export const isStringID = function(string) {
	var re = /^([a-zA-Z0-9_-]+)$/;
	return re.test(string);
}

// check whether string provided is valid numeric id
export const isNumericID = function(string) {
	var re = /^([0-9-]+)$/;
	return re.test(string);
}

// check whether string provided is valid key
export const isKey = function(string) {
	var re = /^([a-zA-Z0-9-_]+)$/;
	return re.test(string);
}

// check whether string provided is valid string name
export const isStringName = function(string) {
	var re = /^([a-zA-Z0-9 _-]+)$/;
	return re.test(string);
}

// check whether string provided is alphanumeric
export const isAlphanumeric = function(string) {
	var re = /^([a-zA-Z0-9]+)$/;
	return re.test(string);
}

// check whether string provided is numeric
export const isNumeric = function(string) {
	var re = /^([0-9]+)$/;
	return re.test(string);
}

// check whether string provided is integer
export const isInteger = function(string) {
	var re = /^([0-9-]+)$/;
	return re.test(string);
}

// check whether string provided is NaN
export const isNan = function(number) {
	return _isNaN(number);
}

// check whether value provided is timestamp
export const isTimeStamp = function(value) {
	return ( ( new Date(value) ).getTime() > 0 ? true : false );
};

// is array exists
export const isArrayExists = function(array) {
	return ( array && !_isEmpty( array ) && _isArray( array ) ? true : false );
}

// check whether value provided is empty or not array
export const arrayNotExists = function(array) {
	return ( !array || _isEmpty( array ) || !_isArray( array ) ? true : false );
}

// is array exists
export const isObjectExists = function(object) {
	return ( object && !_isEmpty( object ) && _isObject( object ) ? true : false );
}

// check if value is contains in array
export const inArray = function(array,val,key) {
	// if key is provided, check based on key
	if ( key && !_isEmpty( key ) ) {
		return ( array && isArrayExists( array ) && _find( array, (a) => a[key] && val === a[key] ) ? true : false );
	} else {
		return ( array && isArrayExists( array ) && _find( array, (a) => val === a ) ? true : false );
	}
}

// check whether string provided is price (max 2 decimal)
export const isPrice = function(string) {
	var valid = false,
		re = /^([0-9.]+)$/;
	if ( re.test(string) ) {
		valid = true;
	}

	return valid;
}

// safe unescape
export const  safeUnescape = function(string) {

	if ( string && !_isEmpty( string ) ) {
		string = _replace( string, '&amp;', '&' );
	} // end - string

	return string;
}