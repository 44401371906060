import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';

import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormInput from '../../components/FormInput';
import FormRadio from '../../components/FormRadio';

import { FlexComponent } from '../../styles/division';
import { ButtonGroup, InfoButton, ErrorButton } from '../../styles/button';
import { SuccessTag } from '../../styles/tag';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';

import { addNewProduct, deleteProduct } from '../../actions/products';

const useStyles = theme => ({
    productcard: {
        background: '#fff',
        padding: '20px 30px',
        height: '100%',
        minHeight: '250px',
        "& > div": {
            height: '100%'
        }
    },
    addnewcard: {
        background: '#fff',
        padding: '20px 30px',
        height: '100%',
        minHeight: '250px',
        "& > div": {
            height: '100%'
        },
        "&:hover": {
            background: '#f1f1f1'
        }
    },
    productimage: {
        width: '100%',
        maxWidth: '150px',
        textAlign: 'center',
        marginBottom: '20px',
        "& > i": {
            fontSize: '75px'
        },
        "& > img": {
            width: '100%',
            maxWidth: '75px',
            height: "auto"
        },
    },
    producttag: {
        marginTop: '15px',
        textTransform: 'uppercase'
    },
    productbuttons: {
        marginTop: '15px'
    }
});

class Products extends React.Component {

    state = {
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    };

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleFormUpdate = (newValue,key) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleView = product => event => {
        const { history } = this.props;
        history.push( '/products/' + product.id );
    }

    handleAddNew = () => {
        const { modalData } = this.state;
        var error = false;

        // if ( !( modalData && modalData.type && !_isEmpty( modalData.type ) ) ) {
        //     error = 'Please select a user type';
        // } // end - modalData.type

        // if ( !( modalData && modalData.status && !_isEmpty( modalData.status ) ) ) {
        //     error = 'Please select a status';
        // } // end - modalData.role

        // if ( !( modalData && modalData.email && validateEmail( modalData.email ) ) ) {
        //     error = 'Please insert a valid email address';
        // } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addNewProduct(modalData));
        } // end - error
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteProduct(deleteModal.id));
    }

    renderAddNewForm = () => {
        const { modalData } = this.state;
        return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormInput label="Product Name" name="name" value={( modalData.name || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={12}>
                    <FormRadio label="Product Type" name="type" value={( modalData.type || '' )} options={[
                        { value: 'plugin', label: 'Plugin' },
                        { value: 'theme', label: 'Theme' },
                        { value: 'bundle', label: 'Bundle or Membership' },
                    ]} onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
        </div>
        );
    }

    renderAddNewBox = () => {
        const { classes } = this.props;
        return (
        <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={2} className={classes.addnewcard} style={{ cursor: 'pointer' }} onClick={() => this.setState({ openViewModal: true, modalData: { 
                    name: '', 
                    type: 'plugin' 
                } })}>
                <FlexComponent direction="column" justify="center" items="center">
                    <div className={classes.productimage}>
                        <i className="fa fa-plus-circle"></i>
                    </div>
                    <Typography variant="h4">ADD NEW</Typography>
                </FlexComponent>
            </Paper>
        </Grid>
        );
    }

    renderProduct = (product) => {
        const { classes } = this.props;
        return (
        <Grid key={product.id} item xs={12} sm={6} md={4}>
            <Paper elevation={2} className={classes.productcard}>
                <FlexComponent direction="column" justify="center" items="center">
                    { product.image && !_isEmpty( product.image ) ? (
                        <div className={classes.productimage}>
                            <img src={product.image} />
                        </div>
                    ) : null }
                    <Typography variant="h4">{product.name}</Typography>
                    <SuccessTag className={classes.producttag}>{product.type || ''}</SuccessTag>
                    <ButtonGroup className={classes.productbuttons}>
                        <InfoButton onClick={this.handleView(product)}><i className="fa fa-search"></i>View</InfoButton>
                        <ErrorButton onClick={() => this.setState({ openDeleteModal: true, deleteModal: product })}><i className="fa fa-trash"></i>Delete</ErrorButton>
                    </ButtonGroup>
                </FlexComponent>
            </Paper>
        </Grid>
        );
    }

    renderProducts = () => {
        const { products } = this.props;
        return (
        <Grid container spacing={2}>
            {this.renderAddNewBox()}
            { products && isArrayExists( products ) ? _map( products, this.renderProduct ) : null }
        </Grid>
        );
    }

    render() {
        const { openDeleteModal, deleteModal, openViewModal } = this.state;
        return (
        <div>

            {this.renderProducts()}

            <ModalView 
                open={openViewModal}
                title="Add New Product"
                actionLabel={"Add New"}
                onClose={() => this.setState({ openViewModal: false, modalData: false })}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this product ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

        </div>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(Products);