export const productSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add'],
        sync: false 
    },
    {   
        id: 'uid',
        label: 'UID',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['add'],
        sync: true
    },
    {   
        id: 'name',
        label: 'Name',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'text',
        sync: true
    },
    {   
        id: 'type',
        label: 'Type',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'radio', // plugin, theme, bundle
        sync: true
    },
    {   
        id: 'image',
        label: 'Product Image',
        required: false,
        default: '',
        type: 'string',
        field: 'upload',
        sync: true
    },
    // server related
    {   
        id: 'memory_limit',
        label: 'Memory Limit (in MB)',
        required: false,
        default: 64,
        type: 'number',
        field: 'text_number',
        sync: true
    },
    {   
        id: 'max_upload_size',
        label: 'Max File Upload Size (in MB)',
        required: false,
        default: 4,
        type: 'number',
        field: 'text_number',
        sync: true
    },

    // demo site related
    {   
        id: 'demo_expires_after',
        label: 'Demo Site Expired After (in seconds)',
        required: false,
        default: 43200, // one hour
        type: 'number',
        field: 'select',
        sync: true
    },

    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update'],
        sync: true
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date',
        sync: true
    }
];