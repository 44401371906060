import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_SANDBOXES,
	GET_SANDBOX,
	CREATE_SANDBOX,
	ADD_SANDBOX,
	UPDATE_SANDBOX,
	UPDATE_SANDBOXES,
	DELETE_SANDBOX,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getSandbox, fb_getSandboxes, fb_createSandbox, fb_editSandbox, fb_editSandboxes, fb_deleteSandbox, fb_cloneSandbox } from './fb';


export const getSandbox = () => {
	return dispatch => {

		fb_getSandbox()
		.then( sandbox => {
			dispatch({ type: GET_SANDBOX, payload: ( sandbox && !_isEmpty( sandbox ) ? sandbox : false ) });
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to get sandbox' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const createSandbox = () => {
	return dispatch => {

		fb_createSandbox()
		.then( result => {

			// re-load sandbox
			fb_getSandbox()
			.then( sandbox => {
				// update sandbox
				dispatch({ type: GET_SANDBOX, payload: ( sandbox && !_isEmpty( sandbox ) ? sandbox : false ) });

				// dispatch updates
				dispatch({ type: CREATE_SANDBOX });
			});
			
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to create sandbox' ) );
			triggerErrorAlert(errMsg);
		});
  		

	}
}

export const getSandboxes = () => {
	return dispatch => {

		fb_getSandboxes()
		.then( list => {
			dispatch({ type: GET_SANDBOXES, payload: { list } });
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to get sandbox' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editSandbox = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_editSandbox(formData)
		.then( results => {
			fb_getSandboxes()
			.then(list => {
				dispatch({ type: GET_SANDBOXES, payload: { list } });	
				dispatch({ type: UPDATE_SANDBOX });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Sandbox Updated");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to edit sandbox' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const cloneSandbox = (id) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_cloneSandbox(id)
		.then( results => {
			fb_getSandboxes()
			.then(list => {
				dispatch({ type: GET_SANDBOXES, payload: { list } });	
				dispatch({ type: UPDATE_SANDBOX });
				dispatch(toggleLoader(false));
				triggerSuccessAlert("Sandbox Cloned");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to clone sandbox' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editSandboxes = (list,formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_editSandboxes(formData)
		.then( results => {
			fb_getSandboxes()
			.then(list => {
				dispatch({ type: GET_SANDBOXES, payload: { list } });	
				dispatch({ type: UPDATE_SANDBOXES });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Sandbox(es) Updated");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to edit sandbox' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteSandbox = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteSandbox(id)
		.then( results => {
			fb_getSandboxes()
			.then(list => {
				dispatch({ type: GET_SANDBOXES, payload: { list } });	
				dispatch({ type: DELETE_SANDBOX });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Sandbox Deleted!");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete sandbox' ) );
			triggerErrorAlert(errMsg);
		});

	}
}