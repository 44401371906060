import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';

import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';

import { InfoButton, GreyButton } from '../../styles/button';
import { FormBox } from '../../styles/form';

import { isAdmin } from '../../helpers/auth';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';

import { productSchema } from '../../schemas/product';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class ProductSettings extends React.Component {

    handleSaveChanges = (event) => {
        const { onSave } = this.props;
        event.preventDefault();

        if ( onSave )
            onSave();
    }

    handleFormUpdate = ( newValue, key ) => {
        const { onUpdate, product } = this.props;
        let newData = ( product && !_isEmpty( product ) ? cloneCollections( product ) : {} );

        switch( key ) {
            // case 'assigned_to':
            //     newData[key] = reverseUsersValues( newValue, users );
            //     break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onUpdate )
            onUpdate( newData );
    }

    getFieldOptions = (field) => {
        const { product } = this.props;

        switch( field.name ) {
            case 'type':
                return [
                    { value: 'plugin', label: 'Plugin' },
                    { value: 'theme', label: 'Theme' },
                    { value: 'bundle', label: 'Bundle or Membership' },
                ];
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { product } = this.props;
        switch( field.name ) {
            default:
                return ( product && product[field.name] || ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData } = this.props;
        var disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                }
            });
        } // end - schema

        return disabled
    }

    getField = (id) => {
        let schema = _find( productSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( schema.default || null ),
            disabled: ( schema.disabled ? this.isFieldDisabled( schema ) : false )
        } : null );
    }

    renderField = (id) => {
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={3} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <div style={{ paddingTop: "7px" }}><FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
                case 'radio':
                    return <div style={{ paddingTop: "7px" }}><FormRadio {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
            }
        } // end - field.field_type
    }

    render() {
        const { classes } = this.props;
        return (
        <Paper elevation={2} style={{ background: '#fff', padding: '30px 20px' }}>

            <FormBox>
                <Typography variant="h4" className={classes.boxheading}>Product Details</Typography>
                <Grid container spacing={3}>

                    <Grid item xs={12}>{this.renderField('name')}</Grid>
                    <Grid item xs={12}>{this.renderField('type')}</Grid>

                </Grid>
            </FormBox>

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 45px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Update</InfoButton>
            </div>

        </Paper>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(ProductSettings);