import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';

import DotsLoader from '../../components/DotsLoader';
import FormInputCopy from '../../components/FormInputCopy';

import { Spaces } from '../../styles/division';
import { InfoButton, ASuccessLink } from '../../styles/button';

import theme from '../../theme';

import { getSandbox, createSandbox } from '../../actions/sandboxes';

const useStyles = theme => ({
    card: {
        padding: "60px 25px",
        background: '#fff'
    }
});

const SandboxWrapper = styled.div`
    display: block;
    max-width: 600px;
    margin: 0 auto;
`;

class CardSandbox extends React.Component {

    state = {
        creating: false,
        status: false,
        randNum: false
    };

    componentDidMount() {
        this.props.dispatch(getSandbox());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { sandBox, randNum } = this.props;
        if ( sandBox && randNum && randNum !== this.state.randNum ) {
            this.setState({ randNum, creating: false });
        } // end - randNum
    }

    handleCreateSandbox = (event) => {
        event.preventDefault();
        this.setState({ creating: true, status: 'start' });
        this.props.dispatch(createSandbox());
    }

    renderLoader = () => {
        const { creating } = this.state;
        return (
        <CardContent>
            <DotsLoader messagePosition="bottom" message={<Typography variant="h4" align="center">{( creating ? 'Creating WordPress Sandbox...' : 'Loading...' )}</Typography>} />
        </CardContent>   
        )
    }

    renderViewSandbox = () => {
        const { sandBox } = this.props;
        return (
        <CardContent>
            <Typography variant="h3" align="center">Your WordPress Sandbox is Ready.</Typography>
            <Spaces lg="15px" />
            <Typography variant="body1" align="center">Here's your login credentials.</Typography>
            <Spaces lg="20px" />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <FormInputCopy label="Sandbox URL" value={( sandBox && sandBox.url || '' )} labelWidth={100} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputCopy label="Email" type="email" value={( sandBox && sandBox.email || '' )} labelWidth={50} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputCopy label="Password" type="password" value={( sandBox && sandBox.password || '' )} labelWidth={80} />   
                </Grid>
            </Grid>
            <Spaces lg="30px" />
            <div style={{ textAlign: 'center' }}>
                <ASuccessLink href={( sandBox && sandBox.url || '#' )} target="_blank">Go to Sandbox Now</ASuccessLink>
            </div>
            <Spaces lg="20px" />
            <Typography variant="body1" align="center" style={{ fontStyle: 'italic' }}>*Please note that this sandbox shall be removed within 24 hours from the time of creation.</Typography>
        </CardContent>
        )
    }

    renderCreateSandbox = () => {
        return (
        <CardContent align="center">
            <Typography variant="h3" align="center">Create Your First WordPress Sandbox</Typography>
            <Spaces lg="30px" />
            <InfoButton onClick={this.handleCreateSandbox}>Let's Get Started Now</InfoButton>
        </CardContent>
        )
    }

    render() {
        const { classes, sandBox } = this.props;
        const { creating, randNum } = this.state;
        return (
        <SandboxWrapper>
            <Card elevation={2} className={classes.card}>
                { creating || !randNum ? this.renderLoader() : ( sandBox && !_isEmpty( sandBox ) ? this.renderViewSandbox() : this.renderCreateSandbox() ) }
            </Card>
        </SandboxWrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        sandBox: state.sandboxes && state.sandboxes.sandbox || null,
        sandBoxUpdated: state.sandboxes && state.sandboxes.updated || null,
        randNum: state.sandboxes && state.sandboxes.rand || null
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(CardSandbox);