import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import Users from './users';

import { cloneCollections } from '../../helpers/data';

import { getUsers } from '../../actions/users';

class UsersPage extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        // get lists
        this.props.dispatch(getUsers());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { usersList, randNum } = this.props;

        // once all the data is loaded
        if ( usersList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    handleRefresh = () => {
        // get users
        this.props.dispatch(getUsers());
        this.setState({ randNum: false });
    } 

    renderContents() {
        const { usersList, authData } = this.props;
        return (
        <Users 
            users={usersList} 
            authData={authData}
            onRefresh={this.handleRefresh} />
        );
    }

    render() {
        const { usersList } = this.props;
        const { randNum } = this.state;
        return <AppWrapper 
                title="Users Management"
                onLoad={( !( usersList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        usersList: state.users && state.users.users || null,
        randNum: state.users && state.users.rand || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(UsersPage);