import _random from 'lodash/random';

import { 
	GET_SANDBOXES,
	GET_SANDBOX,
    CREATE_SANDBOX,
    ADD_SANDBOX,
	UPDATE_SANDBOX,
	DELETE_SANDBOX
} from '../actions/types';

const initialState = {
    sandboxes: null,
    sandbox: null,
    updated: null,
    rand: false
};

export const sandboxes = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_SANDBOXES:
            return {
                ...state,
                sandboxes: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_SANDBOX:
            return {
                ...state,
                sandbox: ( payload || {} ),
                rand: _random(1,9999)
            };
        case CREATE_SANDBOX:
        case ADD_SANDBOX:
        case UPDATE_SANDBOX:
        case DELETE_SANDBOX:
            let randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum,
            };
        default:
            return state;
    }
}