import _random from 'lodash/random';

import { 
	GET_PRODUCTS,
	GET_PRODUCT,
	ADD_PRODUCT,
	UPDATE_PRODUCT,
    DELETE_PRODUCT,
    RESET_PRODUCT
} from '../actions/types';

const initialState = {
    products: null,
    product: null,
    updated: null,
    rand: false
};

export const products = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_PRODUCTS:
            return {
                ...state,
                products: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_PRODUCT:
            return {
                ...state,
                product: ( payload || {} ),
                rand: _random(1,9999)
            };
        case ADD_PRODUCT:
        case UPDATE_PRODUCT:
        case DELETE_PRODUCT:
            let randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum,
            };
        case RESET_PRODUCT:
            return {
                ...state,
                products: null,
                product: null,
                updated: null,
                rand: false,
            };
        default:
            return state;
    }
}